import { render, staticRenderFns } from "./SelezioneArea.vue?vue&type=template&id=626e6572&"
import script from "./SelezioneArea.vue?vue&type=script&lang=js&"
export * from "./SelezioneArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('626e6572')) {
      api.createRecord('626e6572', component.options)
    } else {
      api.reload('626e6572', component.options)
    }
    module.hot.accept("./SelezioneArea.vue?vue&type=template&id=626e6572&", function () {
      api.rerender('626e6572', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Preventivi/SelezioneArea.vue"
export default component.exports