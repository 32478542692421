var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "q-pa-md home" }, [
    _c(
      "div",
      { staticClass: "row justify-center" },
      [
        _c("QQButton", {
          attrs: {
            label: "Indietro",
            color: "primary",
            icon: "undo",
            size: "xl",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        }),
        _vm.isPersoneEnabled()
          ? _c("QQButton", {
              attrs: {
                label: "Persone e Famiglia",
                color: "blue-grey",
                icon: "supervisor_account",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSelezionaArea("persona")
                },
              },
            })
          : _vm._e(),
        _vm.isProfessionistiEnabled()
          ? _c("QQButton", {
              attrs: {
                label: "Professionisti",
                color: "blue-grey",
                icon: "work_outline",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSelezionaArea("professionisti")
                },
              },
            })
          : _vm._e(),
        _vm.isAziendeEnabled()
          ? _c("QQButton", {
              attrs: {
                label: "Aziende",
                color: "blue-grey",
                icon: "contact_mail",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.inCostruzione.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm.isCauzioniEnabled()
          ? _c("QQButton", {
              attrs: {
                label: "Cauzioni",
                color: "blue-grey",
                icon: "weekend",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.inCostruzione.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm.isAltriRamiEnabled()
          ? _c("QQButton", {
              attrs: {
                label: "Altri rami",
                color: "blue-grey",
                icon: "mdi-animation-outline",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.inCostruzione.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm.isRetailEnabled()
          ? _c("QQButton", {
              attrs: {
                label: "Retail",
                color: "blue-grey",
                icon: "mdi-book-open",
                size: "xl",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.inCostruzione.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }